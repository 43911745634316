import React, { useState } from 'react';
import { useAppData } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import Back from '../Back';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer/Footer';
import StatBlock from '../StatsMain/StatBlock';
import dollar_coin from '../../pages/dollar_coin.png';
import icon from './iconUpgrade.png';
import Upgrade from './Upgrade';
import './mine.scss';
import { manageMeme } from '../../http';

const formatNumber = (num) => {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(0) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(0) + 'K';
    }
    return num;
};

export default function Mine() {
    const { t } = useTranslation();
    const { coins, handleEditCoins, memes, getUserMemesData, setpassiveIncomePerHour} = useAppData();
    const navigate = useNavigate();
    const [selectedUpgrade, setSelectedUpgrade] = useState(null);

    const handleUpgradeClick = (upgrade) => {
        setSelectedUpgrade(upgrade);
    };

    const handleCloseModal = () => {
        setSelectedUpgrade(null);
    };

    const handleUpgrade = async (memeId, coins_per_hour, upgrade_cost, upgraded_coins_per_hour) => {
        if (coins >= upgrade_cost) {
            await manageMeme(memeId);
            handleEditCoins(-upgrade_cost)
            setpassiveIncomePerHour(prev => prev + (upgraded_coins_per_hour - coins_per_hour));
            await getUserMemesData();
        }
        setSelectedUpgrade(null);
    };

    return (
        <div className='mines_container'>
            <div className="background">
                <StatBlock className='stats' />
                <div className='row_home'>
                    <div className="stats">
                        {/* <img src={dollar_coin} className="stats-coin" alt="Coin icon" /> */}
                        <div id="balance" className="coin-count">{coins.toLocaleString('en-US')}</div>
                    </div>
                </div>
                <div className="upgrades_container">
                    {memes.map((upgrade) => (
                        <div key={upgrade.id} className="upgrade_item" onClick={() => handleUpgradeClick(upgrade)}>
                            <Upgrade icon={upgrade.logo} title={upgrade.name} profit={upgrade.coins_per_hour} lvl={upgrade.level} upgrade_cost={upgrade.upgrade_cost} />
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
            <Back />
            {selectedUpgrade && (
                <div className="modal">
                    <div className='container_glow_modal'>
                        <div className="modal-container">
                            <span className="close" onClick={handleCloseModal}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="10" fill="#CACACA" fill-opacity="0.46"/>
                                    <path d="M16 8L8 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8 8L16 16" stroke="#131313" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                            <div className="modal-content">
                                <div className="modal-info">
                                    <img src={selectedUpgrade.logo} className="mine-modal-image" alt="Upgrade icon" />
                                    <div className="popup_text">
                                        <h2 className='mine-modal-title'>{selectedUpgrade.name}</h2>
                                        <p className='mine-upgrade-description-text'>{selectedUpgrade.description}</p>
                                    </div>
                                    <div className="mine-upgrade-description">
                                        {selectedUpgrade.level !== 16 ? (
                                            <div className='popup_upgrade_info'>
                                                <span className='profit_per_hour'>
                                                    <p>Profit per hour</p>
                                                    <span className='row_boosts one'>
                                                        <img src="https://tapperstar.xyz/media/quality_new.png" alt="coin" />
                                                        <span>+{formatNumber(selectedUpgrade.upgraded_coins_per_hour)}</span>
                                                    </span>
                                                </span>
                                                <span className='row_boosts two'>
                                                    <img src="https://tapperstar.xyz/media/quality_new.png" alt="coin" />
                                                    <span>{(selectedUpgrade.upgrade_cost).toLocaleString('en-US')}</span>
                                                </span>
                                            </div>
                                        ) : (
                                            <div>{t('boost.maxLevelReached')}</div>
                                        )}
                                    </div>
                                    {selectedUpgrade.lvl !== 16 && (
                                        <button className='button-long' onClick={() => handleUpgrade(selectedUpgrade.meme_id, selectedUpgrade.coins_per_hour, selectedUpgrade.upgrade_cost, selectedUpgrade.upgraded_coins_per_hour)}>Go ahead</button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
