import React, { useState } from 'react';
import "./stat.css";
import { useAppData } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLeague } from './LeagueContext';

const StatBlock = () => {
    const { t } = useTranslation();
    const { passiveIncomePerHour, clickCost, dollar_coin, total_coins_earned } = useAppData();
    const navigate = useNavigate();
    const leagueInfo = useLeague();

    const leagues = [
        { color: '#C7C7C7', threshold: 0 },
        { color: '#91B3F8', threshold: 300000 },
        { color: '#F3CD46', threshold: 1000000 },
        { color: '#37CDEE', threshold: 10000000 },
        { color: '#C019CD', threshold: 50000000 },
    ];

    const formatNumberLeague = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(0) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toFixed(0) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(0) + 'K';
        }
        return num;
    };

    const formatNumber = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1) + 'B';
        } else if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1).toLocaleString('de-DE', { minimumFractionDigits: 1, maximumFractionDigits: 3 }) + 'K';
        }
        return num;
    };

    const getNextLeagueThreshold = () => {
        const thresholds = [300000, 1000000, 10000000, 50000000];
        for (let threshold of thresholds) {
            if (total_coins_earned < threshold) {
                return threshold;
            }
        }
        return "Max";
    };

    const getCurrentLeague = () => {
        for (let i = leagues.length - 1; i >= 0; i--) {
            if (total_coins_earned >= leagues[i].threshold) {
                return i;
            }
        }
        return 0;
    };

    const [isNextLeagueShown, setIsNextLeagueShown] = useState(true);

    const toggleNextLeague = () => {
        setIsNextLeagueShown(!isNextLeagueShown);
    };

    const currentLeagueIndex = getCurrentLeague();
    const currentLeague = leagues[currentLeagueIndex];

    const filledStarsArray = Array(currentLeagueIndex + 1).fill(currentLeague.color);
    const emptyStarsArray = Array(5 - filledStarsArray.length).fill('#000000');

    return (
        <div className="stat-block">
            <div className="stat-item" onClick={() => navigate("/boosts")}>
                <div className='stat_text' style={{ color: "#FCD36B" }}>{t('statBlock.profitPerClick')}</div>
                <div>
                    <span style={{ fontSize: "18px" }}>+{clickCost}</span>
                    <img src="https://tapperstar.xyz/media/quality_new.png" alt={t('statBlock.profitPerClick')} />
                </div>
            </div>
            <div className="stat-item" onClick={toggleNextLeague}>
                <div className='stat_text' style={{ color: "#FFA02B" }}>{t('statBlock.nextLeague')}</div>
                <div>
                    <span style={{ fontSize: "18px" }}>
                        {formatNumberLeague(getNextLeagueThreshold())}
                    </span>
                </div>
            </div>
            <div className="stat-item" onClick={() => navigate("/mine")}>
                <div className='stat_text' style={{ color: "#7CFAA4" }}>{t('statBlock.profitPerHour')}</div>
                <div>
                    <span style={{ fontSize: "18px" }}>{passiveIncomePerHour == null ? 0 : `+${formatNumber(passiveIncomePerHour)}`}</span>
                    <img src="https://tapperstar.xyz/media/quality_new.png" alt={t('statBlock.profitPerHour')} />
                </div>
            </div>
        </div>
    );
};

export default StatBlock;
